import { Show } from 'solid-js';
import { Marked } from 'marked';

import Spinner from './Spinner';
import Locale, { ls } from './Locale';

import style from './Message.module.css';

export default (props) => {
    const marked = new Marked({ async: false });
    return (
        <div>
            <div class="my-2 flex flex-row items-center justify-start">
                <img src={props.pfp ? props.pfp : `${DIST_URL}/pfp.svg`} class="w-8 h-8 rounded-full"/>
                <span class="ml-2 italic">{ls(props.user)}:</span>
                <Show when={props.flag}>
                    <button onClick={props.flag}><img src={`${DIST_URL}/flag.svg`} class="ml-2 w-4 h-4"/></button>
                </Show>
            </div>
            <Show when={props.message}>
                {props.md ? <div class={`ml-4 ${style.message}`} innerHTML={marked.parse(props.message)}></div> : <div class={`ml-4 ${style.message}`}>{props.message}</div>}
            </Show>
            <Show when={props.error}>
                <div class="mt-2 w-full p-2 border-red-700 border-2 bg-red-700 bg-opacity-45 rounded-md">
                    <div class="flex flex-row items-center justify-start">
                        <img class="w-6 h-6" src={`${DIST_URL}/exclamation_mark.svg`}/>
                        <span class="ml-2 font-bold">{ls('Error')}:</span>
                    </div>
                    <p>{props.error}</p>
                </div>
            </Show>
            {/* <Show when={!props.progress && props.user == 'Assistant'}>
                <div class="mt-2 p-2 inline-block bg-neutral-700 bg-opacity-55 rounded-md">
                    <div class="flex flex-row items-center justify-start">
                        <span class="italic mr-2">Was this response helpful?</span>
                        <div class="flex flex-row items-center justify-center">
                            <button><img src="up.svg" class="w-6 h-6 mix-blend-difference"/></button>
                            <button><img src="down.svg" class="w-6 h-6 mix-blend-difference"/></button>
                        </div>
                    </div>
                </div>
            </Show> */}
            <Show when={props.progress}>
                <Spinner class="ml-2 w-4 h-4 inline-block"/>
            </Show>
            {/* <Show when={props.controls}>
                <div class="ml-4 flex flex-row items-center justify-start">
                    <button class="w-4 h-4"><img src="clipboard.svg" class="w-full h-full"/></button>
                </div>
            </Show> */}
        </div>
    );
}