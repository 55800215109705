import { createSignal, Show, createEffect, onMount, Switch, Match } from 'solid-js';

import Spinner from './Spinner';
import Message from './Message';
import Locale, { ls, locale, setLocale } from './Locale';

import style from './Chat.module.css';
import Dropdown from './Dropdown';
import { resource, fetchres } from './Resource';

export default (props) => {
    const [session, setSession] = createSignal(INT_SESSION);

    const prev = sessionStorage.getItem('history');
    let history = prev ? JSON.parse(prev) : undefined;
    setLocale(history?.locale || navigator.language);
    const [thread, { mutate, refetch }] = resource(
        () => fetchres(`${DIST_URL}/chat/thread?s=${session()}`), 
        { value: history?.thread }
    );

    console.log(history);
    const [messages, setMessages] = createSignal(history?.messages || []);
    const [current, setCurrent] = createSignal();

    const [dark, setDark] = createSignal(history ? history?.dark : window.matchMedia('(prefers-color-scheme: dark)').matches);
    const [suggestion, setSuggestion] = createSignal(thread()?.session?.suggestions ? thread().session.suggestions[0] : undefined);

    console.log(history);

    let chat;
    let textarea;
    const submit = async () => {
        const inp = textarea.value;
        textarea.value = '';
        if (inp.length <= 1) {
            return;
        }
        setMessages([ ...messages(), { user: thread()?.session?.name || 'User', pfp: thread()?.session?.pfp, message: inp } ]);
        setCurrent({ user: 'Assistant', message: '' });
        chat.scrollBy({ behavior: 'smooth', top: chat.scrollHeight });
        const req = new EventSource(`${DIST_URL}/chat/message?t=${thread()?.id}&m=${inp}&s=${session()}`);
        req.addEventListener('delta', ({ data }) => {
            const content = JSON.parse(data);
            console.log(content);
            for (let value of content) {
                console.log(value?.text?.annotations?.length)
                if (value?.text?.annotations?.length > 0) {
                    for (let each of value?.text?.annotations) {
                        setCurrent({ user: 'Assistant', message: current().message.replaceAll(each.text, `${DIST_URL}/file?id=${each.file_path.file_id}`) });
                    }
                } else if (value.type == 'image_file') {
                    setCurrent({ user: 'Assistant', message: current().message += `\n![${value?.type}](${DIST_URL}/file?id=${value?.image_file?.file_id})\n` });
                } else if (value.type == 'image_url') {
                    setCurrent({ user: 'Assistant', message: current().message += `\n![${value?.type}](${value?.image_url?.url})\n` });
                } else {
                    setCurrent({ user: 'Assistant', message: current().message + value?.text?.value });
                }
                chat.scrollBy({ behavior: 'instant', top: chat.scrollHeight });
            }
        });
        req.addEventListener('error', (e) => {
            e.preventDefault();
            setMessages([ ...messages(), { user: 'Assistant', error: `${ls('An error has occurred')}.` } ]);
            setCurrent();
            chat.scrollBy({ behavior: 'instant', top: chat.scrollHeight });
            return req.close();
        });
        req.addEventListener('end', ({ data }) => {
            const content = JSON.parse(data);
            console.log(content, thread()?.token_balance);
            mutate({ ...thread(), token_balance: thread()?.token_balance - content?.usage });
            console.log(content);
            setMessages([ ...messages(), { user: 'Assistant', message: current().message, error: content?.error?.message } ]);
            if (thread()?.token_balance <= 0) {
                setMessages([ ...messages(), { user: 'Assistant', message: `${ls('You\'ve reached your usage limit, try again on')} ${(new Date(Date.parse(thread()?.last_grant) + 86400000)).toLocaleString()}.` } ]);
            }
            setCurrent();
            return req.close();
        });
    };

    const flag = async ({ message }) => {
        const req = await fetch(`${DIST_URL}/chat/flag?t=${thread()?.id}&m=${message}&s=${session()}`);
        setMessages([ ...messages(), { user: 'Info', message: ls(req.ok ? 'Message flagged successfully.' : 'Unable to flag message.') } ]);
    };

    const del = () => {
        history = null;
        sessionStorage.clear('history');
        textarea.value = '';
        setMessages([]);
        setCurrent();
        refetch();
    };

    createEffect(() => {
        if (thread()) {
            if (visible() || !current()) {
                textarea.focus();
            }
        }
    });

    createEffect(() => {
        if (!window.frameElement) {
            return;
        }
        if (visible()) {
            window.frameElement.style = `position: fixed; width: 100%; height: 100%; z-index: 15;`
        } else {
            window.frameElement.style = `position: fixed; width: 4em; height: 2em; bottom: 2em; right: 2em; z-index: 15;`
        }
    });
    
    createEffect(() => {
        if (visible() && messages()?.length < 1) {
            if (!thread()?.session?.suggestions) {
                return;
            }
            const len = thread()?.session?.suggestions?.length;
            const rand = Math.random();
            for (let i = 1; i <= len; i++) {
                console.log(i/len, rand);
                if (i/len >= rand) {
                    setSuggestion(thread()?.session?.suggestions[i-1]);
                    return;
                }
            }
        }
    });

    onMount(() => {
        window.addEventListener('resize', () => {
            if (visible()) {
                setExpanded(window.innerWidth < 1200);
            }
        });

        window.addEventListener('beforeunload', () => {
            sessionStorage.setItem('history', JSON.stringify({ messages: messages(), session: session(), thread: thread(), locale: locale(), dark: dark() }));
        });

        window.addEventListener('message', ({ data }) => {
            setSession(data || '');
            if (history?.session != session()) {
                console.log(history, history?.session, session());
                del();
            }
        });

        document.addEventListener('keydown', (e) => {
            if (visible()) {
                if (e.target != document.body) {
                    return;
                }
                switch (e.code) {
                case 'NumpadDivide':
                case 'Slash':
                    e.preventDefault();
                    textarea.focus();
                    break;
                case 'F':
                    setExpanded(!expanded());
                    break;
                case 'Delete' && e.shiftKey:
                    del();
                    break;
                }
            }
        });
    });

    const [visible, setVisible] = createSignal(false);
    const [expanded, setExpanded] = createSignal(false);

    return (
        <div class={`${style[dark() ? 'dark' : 'light']} w-full h-full`}>
            <div class={`fixed top-0 left-0 w-svw h-svh flex flex-row justify-end ${visible() ? '' : 'hidden'}`}>
                <div class={`${expanded() ? 'w-full h-full' : 'w-[22em] h-full' }`} style="transition: all .25s ease-out;">
                    <div class={`${expanded() ? '' : 'pb-4 pr-4'} w-full h-full flex flex-col items-end justify-end`} style="transition: all .25s ease-out;">
                        <div class={`${expanded() ? 'w-full h-full' : 'w-full h-3/4 rounded-2xl'} flex flex-col items-center justify-between bg-neutral-800`} style="transition: all .25s ease-out;">
                            <div ref={chat} class={`${expanded() ? '' : 'rounded-2xl'} w-full h-full overflow-y-auto scroll-smooth flex flex-col items-center justify-between`} style="scrollbar-color: gray transparent;">
                                <div class="w-full h-1/12 p-2 flex flex-row items-center justify-between bg-neutral-full bg-opacity-50 backdrop-blur sticky top-0" style="background: linear-gradient(162deg, rgb(50, 67, 150), rgb(88, 105, 188) 75%);">
                                    <div class="w-2/6 flex flex-row items-center justify-start">
                                        <button onClick={() => setExpanded(!expanded())} class="mx-2 w-8 h-8 p-1"><img src={expanded() ? `${DIST_URL}/retract.svg` : `${DIST_URL}/expand.svg`} width="100%" height="100%"/></button>
                                        <button onClick={del} class="w-8 h-8 p-1"><img src={`${DIST_URL}/delete.svg`} width="100%" height="100%"/></button>
                                        {/* <button onClick={() => setSettings(!settings())} class="w-8 h-8 p-1"><img src={`${DIST_URL}/settings.svg`} width="100%" height="100%"/></button> */}
                                    </div>
                                    <Dropdown class="w-2/6 h-full">
                                        <div class="w-full flex flex-row items-center justify-center">
                                            <h1 class="text-center text-2xl font-bold text-white">Chat</h1>
                                            <span class="bg-black ml-2 px-2 rounded-md" style={`color: rgb(${255 - (thread()?.token_balance / thread()?.token_grant * 255)} ${thread()?.token_balance / thread()?.token_grant * 255} 0);`}>%{thread()?.token_balance ? (100 - (thread()?.token_balance / thread()?.token_grant * 100)).toFixed() : '?'}</span>
                                        </div>
                                        <div>
                                            <h1 class="text-center font-bold text-xl">{ls('Settings')}</h1>
                                            <div>
                                                <div class="flex flex-row items-center justify-between my-2 *:mx-2">
                                                    <span>{ls('Theme')}:</span>
                                                    <button class="w-6 h-6" onClick={() => setDark(!dark())}><img width="100%" height="100%" class="mix-blend-difference" src={dark() ? `${DIST_URL}/dark.svg` : `${DIST_URL}/light.svg`}></img></button>
                                                </div>
                                                <div class="flex flex-row items-center justify-between my-2 *:mx-2">
                                                    <span>{ls('Language')}:</span>
                                                    <Locale/>
                                                </div>
                                                <div class="flex flex-row items-center justify-between my-2 *:mx-2">
                                                    <span>{ls('Token Balance')}:</span>
                                                    <span>{thread()?.token_balance}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </Dropdown>
                                    <div class="w-2/6 flex flex-row items-center justify-end">
                                        <button onClick={() => setVisible(!visible())} class="mx-2 w-8 h-8 p-1"><img src={`${DIST_URL}/x.svg`} width="100%" height="100%"/></button>
                                    </div>
                                </div>
                                <Show when={!current() && messages().length <= 0}>
                                    <div class="w-full h-full text-center flex flex-col items-center justify-center">
                                        <h1 class="text-2xl font-bold">{thread()?.session ? `${ls('Welcome')}, ${thread()?.session?.name?.split(' ')[0]}!` : ls('Welcome!')}</h1>
                                        <div>
                                            {thread()?.session?.role 
                                                ? 
                                                <p>
                                                    {ls('You\'re a: ')}
                                                    <Dropdown class="inline">
                                                        <span><abbr title={thread()?.session?.role}>{ls(thread()?.session?.role)}</abbr>.</span>
                                                        <div class="p-2">
                                                            <h1 class="font-bold text-xl">{ls(thread()?.session?.role)}</h1>
                                                            <div>
                                                                <p>{ls('You currently have access to')}:</p>
                                                                <ul class="list-disc text-left pl-4">
                                                                    {thread()?.session?.access?.map((v) => <li>{v};</li>)}
                                                                    <li>{ls('Other self-contained actions')}.</li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </Dropdown>
                                                </p>
                                                : <p>{ls('Type something in to get started.')}</p>
                                            }
                                        </div>
                                        <Show when={thread() && suggestion()}>
                                            <div class="my-4 flex items-center justify-center">
                                                <p onClick={() => { textarea.value = ls(suggestion()); submit() }} class="italic underline cursor-pointer">{ls(suggestion())}</p>
                                            </div>
                                        </Show>
                                        <div class="my-4">
                                            <Switch>
                                                <Match when={thread.loading}>
                                                    <Spinner class="w-4 h-4"/>
                                                </Match>
                                                <Match when={thread.error}>
                                                    <span class="text-red-700 italic">{thread.error}</span>
                                                </Match>
                                            </Switch>
                                        </div>
                                    </div>
                                </Show>
                                <div class={`${expanded() ? 'w-3/4' : 'w-11/12' } *:my-4`}>
                                    {messages()?.map((v) =>
                                        <Message {...v} md={v.user == 'Assistant'} error={v.error} flag={v.user == 'Assistant' ? () => flag(v) : undefined}/>
                                    )}
                                    <Show when={current()}>
                                        <Message {...current()} md={current()?.user == 'Assistant'} progress/>
                                    </Show>
                                </div>
                                <Show when={thread()?.token_balance <= 0}>
                                    <div class="my-4 w-11/12 p-2 border-neutral-400 border-2 bg-neutral-400 bg-opacity-45 rounded-md">
                                        <div class="flex flex-row items-center justify-start">
                                            <img class="w-6 h-6" src={`${DIST_URL}/exclamation_mark.svg`}/>
                                            <span class="ml-2 font-bold">{ls('Notice')}:</span>
                                        </div>
                                        <p>{`${ls('You\'ve run out of tokens, try again on')} ${(new Date(Date.parse(thread()?.last_grant) + 86400000)).toLocaleString()}.`}</p>
                                    </div>
                                </Show>
                            </div>
                            <div class={`${expanded() ? 'w-3/4' : 'w-11/12' } h-1/12 py-4 flex flex-row items-center justify-center`} style="transition: all .25s ease-out;">
                                <textarea ref={textarea} onKeyDown={({ key, shiftKey }) => { if (key == 'Enter' && !shiftKey) { submit() } }} class="w-full h-10 p-2 mr-2 resize-none bg-neutral-700 outline-none rounded-md" placeholder={ls('Type in a question...')} disabled={!!current() || !thread() || thread.loading}></textarea>   
                                <div class="w-min-content h-full ml-2 flex flex-row items-center justify-center">
                                    <Show when={current()}>
                                        <Spinner class="w-8 h-8"/>
                                    </Show>
                                    <Show when={!current()}>
                                        <button onClick={submit} disabled={current() || !thread()} class="w-full h-10 p-2 bg-black text-white rounded-md">{ls('Send')}</button>
                                    </Show>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <button class={`w-full h-full flex flex-row items-center justify-center ${visible() ? 'hidden' : ''}`} onClick={() => setVisible(!visible())}>
                <img src={`${DIST_URL}/temp.png`} class="h-full"/>
            </button>
        </div>
    );
}